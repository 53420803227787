import styled from '@emotion/styled'
import Head from 'next/head'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { Themed } from 'theme-ui'

import { Container } from '../components/UIComponents'
import { homeEnglishLocale, homeSwedishLocale } from '../locales'

/**
 * Renders the Home component.
 *
 * @returns {HTMLElement} - The HTML Markup.
 */
const Home = () => {
  const router = useRouter()
  const { locale } = router
  const t = locale === 'en' ? homeEnglishLocale : homeSwedishLocale

  return (
    <>
      <Head>
        <title>Start - JC Psykologmottagning AB</title>
        <meta
          name="Description"
          content="Välkommen till JC Psykologmottagning AB i centrala Helsingborg
        Verksamheten utför behandlingar inom ramen för vårdval psykoterapi, på uppdrag av Region Skåne."
        />
        <link rel="icon" href="/img/logo.svg" />
      </Head>
      <Container>
        <HomeStyles>
          <div className="col-md-6">
            <Themed.h1 >{t.welcome}</Themed.h1>
            <Themed.p>{t.welcomeInfo}</Themed.p>
            <Themed.p>
              {t.welcomeOne}{' '}
              <a href="http://www.psykologforbundet.se/" target="_blank" rel="noreferrer">
                {t.welcomeTwo}
              </a>{' '}
              {t.welcomeThree}{' '}
              <a
                href="http://www.psykologforbundet.se/psykologforetagarna/"
                target="_blank"
                rel="noreferrer"
              >
                {t.welcomeFour}
              </a>{' '}
              {t.welcomeFive}
            </Themed.p>

            <Themed.h2>{t.costTitle}</Themed.h2>
            <Themed.p>{t.costParagraphOne}</Themed.p>
          </div>

          <div className="col-md-3">
            <Themed.p>{t.feelingOne}</Themed.p>

            <Themed.p>{t.feelingTwo}</Themed.p>
            <Themed.ul>
              <li>{t.feelingThree}</li>
              <li>{t.feelingFour}</li>
              <li>{t.feelingFive}</li>
            </Themed.ul>
            <Themed.p>{t.feelingSix}</Themed.p>
            <Themed.p>{t.feelingSeven}</Themed.p>
            {/* PDT */}
          {/*   <Themed.p>{t.feelingEight}</Themed.p> */}
            <Image src="/img/torn.jpg" className="fadein" alt="Torn" width={300} height={300} priority />
          </div>
        </HomeStyles>
      </Container>
    </>
  )
}

const HomeStyles = styled.section`
  p a {
    color: black;
    text-decoration: none;
  }
`

export default Home
